import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { format } from 'date-fns';
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'


const DiscographyList = ({ title, items }) => {

    const query = useStaticQuery(graphql`
        query {
          markdownRemark(frontmatter: { templateKey: { eq: "music-page" } }) {
            frontmatter {
              discography {
                title
                notes
                label
                image {
                  childImageSharp {
                    fluid(maxWidth: 864, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                date
                spotifyPlaylistEmbed
              }
            }
          }
        }
      `
    );

    const musicList = query.markdownRemark.frontmatter.discography;
    
    return (
        <section className="">
            <div className="container mx-auto">
                <div className="grid grid-cols-6 mb-72 gap-y-32 lg:mb-0 lg:grid-cols-12 lg:gap-y-36 items-start">
                    {musicList.map((item, index) => {
                        const releaseYear = format(new Date(item.date), 'yyyy');

                        return (
                            <>
                                <div className="col-span-6 lg:col-span-8">
                                    <Img fluid={ item.image.childImageSharp.fluid } className=" " />
                                </div>
                                <div className="col-span-6 lg:col-start-10 lg:col-span-3">
                                    <h2 className="text-white font-serif text-32 leading-48">{ item.title }</h2>
                                    <h3 className="text-gray-100 text-16 mb-24">{ item.label } <time>({ releaseYear })</time></h3>

                                    <ReactMarkdown className="text-gray-100 text-12 mb-24 leading-6">{item.notes }</ReactMarkdown>
                                    <div dangerouslySetInnerHTML={{__html: item.spotifyPlaylistEmbed }} />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default DiscographyList
