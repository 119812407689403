import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../components/SiteMetadata'

import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner/PageBanner'
import DiscographyList from '../components/DiscographyList'
import StreamingList from '../components/StreamingList'


const MusicPage = ({ data }) => {
  const { title } = useSiteMetadata()
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{`${frontmatter.title} | ${title}`}</title>
      </Helmet>
      <section>
        <PageBanner title={ frontmatter.title } />

        <DiscographyList />

        <div className="container my-72 hidden md:block lg:my-176">
          <h3 className="text-24 lg:text-32 font-serif uppercase tracking-wider mb-24">
            Stream Music
          </h3>
          <StreamingList />
        </div>

      </section>

    </Layout>
  )
}

MusicPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MusicPage

export const pageQuery = graphql`
  query MusicPage {
    markdownRemark(frontmatter: { templateKey: { eq: "music-page" } }) {
      frontmatter {
        title
      }
    }
  }
`
